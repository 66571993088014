/* --------------------
    States
-------------------- */
let showMenu = false
let langExpanded = false
let searchExpanded = false

/* --------------------
    Event Handler
-------------------- */
$(document).on("click", ".hamburger", toggleMenu)
$(document).on("click", ".search-button", toggleSearch)
$(document).on("click", ".language-button, .language__close", toggleLanguage)
$(document).on("click", ".overlay", closeNavigationAll)

/* --------------------
    Functions
-------------------- */
function toggleMenu() {
  if (!showMenu) {
    closeNavigationAll()
    openMenu()
  } else {
    closeMenu()
  }
}

function openMenu() {
  showMenu = true
  $(".nav-main").addClass("is-active")
  $("body").addClass("no-body-scrolling")
  $(".hamburger").addClass("is-active")
  $(".overlay").addClass("is-active")
  showMenu = true
}

function closeMenu() {
  $(".nav-main").removeClass("is-active")
  $("body").removeClass("no-body-scrolling")
  $(".hamburger").removeClass("is-active")
  $(".overlay").removeClass("is-active")
  showMenu = false
}

function toggleSearch() {
  if (!searchExpanded) {
    closeNavigationAll()
    openSearch()
  } else {
    closeSearch()
  }
}

function openSearch() {
  $(".suche-bild").addClass("icn--close")
  $(".suche-bild").removeClass("icn--search")
  $(".suche").addClass("search-active")
  $(".search-button").addClass("button-active")
  searchExpanded = true
}

function closeSearch() {
  $(".suche-bild").removeClass("icn--close")
  $(".suche-bild").addClass("icn--search")
  $(".suche").removeClass("search-active")
  $(".search-button").removeClass("button-active")
  searchExpanded = false
}

function closeLanguage() {
  if (langExpanded) {
    langExpanded = false
    $(".language").removeClass("open")
  }
}

function openLanguage() {
  if (!langExpanded) {
    closeSearch()
    langExpanded = true
    $(".language").addClass("open")
  }
}

// ändernt Icon zum Schließen der Sprachauswahl
function toggleLanguage() {
  if (!langExpanded) {
    closeNavigationAll()
    openLanguage()
  } else {
    closeLanguage()
  }
}

function closeNavigationAll() {
  closeLanguage()
  closeSearch()
  closeMenu()
}

//sets the focus on the search when the search icon is clicked
function focusSearch() {
  document.getElementsByClassName("searchbar")[0].focus()
}
