$(document).ready(function () {
    initLogoSlider()
});

function initLogoSlider() {
    $(".logo-slider").each((key, item) => {
        $(item).slick({
            infinite: true,
            slidesToShow: 3,
            prevArrow: $(item).parent().find('.arrow--previous'),
            nextArrow: $(item).parent().find('.arrow--next'),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        })
    })
}