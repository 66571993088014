document.addEventListener("DOMContentLoaded", function (event) {
  var icons = document.getElementsByClassName("accordion__container")
  if (icons) {
    var icons = document.querySelectorAll(".accordion__container")
    icons.forEach((element) => {
      if (!$(element).hasClass("is-fixed")) {
        element.addEventListener("click", showText)
      }
    })
  }
})

function showText() {
  this.classList.toggle("text-active")

  var text = this.children[1]

  if (text.style.display === "block") {
    text.style.display = "none"
  } else {
    text.style.display = "block"
  }
}
