/**
 * Event Handler
 */
document.addEventListener("DOMContentLoaded", function (event) {
  $(".locator__icon").on('click', function() {
    if ($(this).parent().hasClass("is-active")) {
      $(this).parent().removeClass("is-active")
    } else {
      $(".locator").removeClass("is-active")
      $(this).parent().addClass("is-active")
    }
  });
});