$(document).ready(function () {
  $(".openpopup").on("click", openPopup)
  $(".popup__close, .overlay").on("click", closePopup)
  initPopupSlider()
})

function openPopup() {
  var popupId = "popup-" + $(this).data("popup")
  if ($(this).data("popup")) {
    $("#" + popupId).addClass("is-active")
    $("body").addClass("no-body-scrolling")
    $(".overlay").addClass("is-active")
  } else {
    console.log("no Popup with ID " + popupId + " found!");
  }
}

function closePopup() {
  $("body").removeClass("no-body-scrolling")
  $(".popup").removeClass("is-active")
  $(".overlay").removeClass("is-active")
}

function initPopupSlider() {
  $(".popup__image").each((key, item) => {
    $(item).slick({
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
    })
  })
}