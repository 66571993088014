/**
 * Event Handler
 */
document.addEventListener("DOMContentLoaded", function (event) {
  initLabelSlider();
  $(".labels__item").hover(playLabelsVideo, stopLabelsVideo);
  $(".show-more-labels").on("click", showMoreLabels);
});

/**
 * Variables
 */
var labelVideoProgressInterval

/**
 * Functions
 */
function initLabelSlider() {
  $(".labels__slider").each((key, item) => {
    $(item).slick({
      infinite: false,
      speed: 300,
      variableWidth: true,
      slidesToShow: 2,
      draggable: false,
      prevArrow: $(item).parent().find('.arrow--previous'),
      nextArrow: $(item).parent().find('.arrow--next'),
      responsive: [
        {
          breakpoint: 1260,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })
  })
}

function playLabelsVideo() {
  if($(this).find('video')[0]!==undefined){
    $(this).find('video')[0].play()
    $(this).find('.label-video__progress').fadeIn()

  labelVideoProgressInterval = setInterval(() => {
    var progressBar = $(this).find('.label-video__progress > div')
    var duration = $(this).find('video')[0].duration
    var currentTime = $(this).find('video')[0].currentTime
    $(progressBar).css("width", 100 * currentTime / duration + '%')
  }, 100);
  }else{
    return;
  }
  
}

function stopLabelsVideo() {
  if($(this).find('video')[0]!==undefined){
  $(this).find('video')[0].pause()
  $(this).find('video')[0].currentTime = 0
  $(this).find('.label-video__progress').fadeOut()
  }else{
    return;
  }
  
}

function showMoreLabels() {
  if ($(this).hasClass("readmore--x")) {
    $(this).removeClass("readmore--x")
    $(this).parent().find(".labels__more-grid").slideUp()
    $(this).html($(this).data("more-labels"))
  } else {
    $(this).addClass("readmore--x")
    $(this).html($(this).data("less-labels"))
    $(this).parent().find(".labels__more-grid").slideDown()
  }
}