$(document).ready(function () {
  initFactListeners()
})

function initFactListeners() {
  /**
   * for Facts Module
   */
  $(".facts__teaser, .facts-image__teaser").click(function () {
    if ($(this).data("detail")) {
      const clickedPoint = $(this).data("detail")
      showDetail($(this), clickedPoint)
    }
  })

  $(".facts__detail-close, .facts-image__detail-close").click(function () {
    closeDetails($(this))
  })
}

function closeDetails(item) {
  // console.log($(item).parent().parent())
  $(item).parent().css({
    opacity: 0,
    "pointer-events": "none",
  })

  $(item).parent().parent().find(".facts__grid, .facts-image__grid").css({
    opacity: 100,
    "pointer-events": "initial",
  })
}

function showDetail(item, id) {
  var container = $(item).parent().parent()
  container.find("#" + id).css({
    opacity: 100,
    "pointer-events": "initial",
  })

  container.find(".facts__grid, .facts-image__grid").css({
    opacity: 0,
    "pointer-events": "none",
  })
}
