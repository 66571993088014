$(document).ready(function () {
    initStageSlider();
});

function initStageSlider() {

    const sliderElement = $(".stage-slider__wrapper");
    sliderElement.on("init", function (event, slick) {
        let width = ((1 / slick.slideCount) * 100) + "%";
        slick.$slider.parent().find(".stage-slider__indicator").css("width", width);
    });
    sliderElement.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        let width = (1 / slick.slideCount * (nextSlide + 1) * 100) + "%";
        slick.$slider.parent().find(".stage-slider__indicator").css("width", width);
    })
    sliderElement.slick({
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 500,
    });
}
