//beim scrollen füllt sich der balken von links nach rechts auf 
//startet bei 10%

window.onscroll = function () {scrollprogress()};

function scrollprogress() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 90;
  var minBreite = 10;
  var progress = document.getElementsByClassName('progress-bar')[0];

  progress.style.width = minBreite + scrolled + "%";
}