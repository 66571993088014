$(document).ready(function () {
    initStoriesSlider()
});

function initStoriesSlider() {
    $(".stories").each((key, item) => {
        $(item).slick({
            infinite: true,
            prevArrow: $(item).parent().find('.arrow--previous'),
            nextArrow: $(item).parent().find('.arrow--next'),
        })
    })
}