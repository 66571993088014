/**
 * global Variables
 */
var video, loop, videoTimingFunction

/**
 * Event Handler
 */
document.addEventListener("DOMContentLoaded", function(event) {
  var playbutton = document.querySelector(".playbutton")
  if (playbutton) {
    playbutton.addEventListener("click", playVideo)
  }

});

/**
 * Functions
 */
function playVideo() {
  video = this.parentNode.querySelector(".video-full")
  loop = this.parentNode.querySelector(".video-loop")
  if (video.paused) {
    video.play()
    loop.style.display = "none"
    video.style.display = "block"
    this.parentNode.classList.add("is-playing")
    videoTimingFunction = setInterval(updateVideoCircle, 100)
    video.parentNode.querySelector(".playbutton__icon").classList.add("is-playing")
  } else {
    video.pause()
    loop.style.display = "block"
    video.style.display = "none"
    this.parentNode.classList.remove("is-playing")
    video.parentNode.querySelector(".playbutton__icon").classList.remove("is-playing")
    clearInterval(videoTimingFunction)
  }
}

function updateVideoCircle() {
  if (video.ended) {
    clearInterval(videoTimingFunction)
  }
  var state = 227 - 227 / video.duration * video.currentTime
  video.parentNode.querySelector("svg").style.strokeDashoffset = state
}